@import "~react-image-gallery/styles/scss/image-gallery.scss";

.offer-header {
    position: relative;

    .share-and-save {
        position: absolute;
        z-index: 1;
        align-content: end;
        right: 0;
        margin-top: 50px;
        margin-right: 30px;
    }

    .favorite {
        height: 50px;
        background-color: white;
        border-radius: 50px;
        align-items: center;
        width: 90px;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .no-of-saves {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: #3B4B53;
    }

    .image-gallery {
        .image-gallery-image {
            width: 100%;
            height: 600px;
            object-fit: cover;
        }
    }
}

.offer-details {
    background-color: white;
    border-radius: 8px;
    padding: 54px;

    .offer-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 59px;
        color: #4B86B4;
    }

    .offer-details-header {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 41px;
        color: #3B4B53;
    }

    .line-divider {
        width: 100%;
        height: 0px;
        margin-top: 20px;
        border: 1px solid #BABFCE;
    }

    .line-vertical-divider {
        width: 0px;
        height: 70px;
        border: 1px solid #BABFCE;
    }

    .title {
        font-family: 'Montserrat';
        font-style: medium;
        font-weight: bold;
        font-size: 20px;

        color: #3B4B53;
    }

    .price-value {
        font-family: 'Montserrat';
        font-style: bold;
        font-weight: bold;
        font-size: 20px;
        color: #4B86B4;
    }

    .auction-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        font-style: bold;
        line-height: 53px;
        color: #3B4B53;
    }

    .auction-value {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        font-style: bold;
        color: #4B86B4;
    }

    .category-details {
        margin-top: 30px;
        padding: 30px;
        padding-top: 0px;
        background: rgba(186, 191, 206, 0.1);
        border-radius: 12px;

        .category-details-item {
            margin-top: 30px;
        }

        .details-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: #3B4B53;
        }

        .details-value {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #4B86B4;
        }

        .description {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #3B4B53;
        }
    }

}