@import "../../styles/variables.scss";

.user-info {
  padding-top: 55px;
  display: flex;
  flex-direction: column;
  max-width: 407px;
  margin: 0 auto;
  
  button {
    border: none;
    width: 100%;
    border-radius: 12px!important;
    font-family: "Montserrat" !important;
  }
}

.user-nav-items {
  padding: 50px 0;
}

.nav-item {
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  background: transparent;
  color: white;
  font-size: 16px;
  font-weight: 500;

  img {
    margin-left: 10px;
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}

.selected-item {
  display: flex;
  background-color: $secundaryOrange;
  padding: 13px 0 13px 0;
  margin-top: 16px;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 500;

  img {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 10px;
  }
}

.coins-info {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
  padding: 13px 0 13px 0;
  align-items: center;
  margin-right: 0px;

  p {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
    color: $secundaryOrange;
  }

  img {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }
}

.user-details {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  img {
    max-width: 60px;
    width: 100%;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .user-contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .p1 {
      color: white;
      font-family: "Montserrat";
      font-style: normal;
      font-size: 16px;
      line-height: 27px;
    }

    .p2 {
      color: white;
      font-family: "Montserrat";
      font-style: normal;
      font-size: 14px;
      line-height: 27px;
    }
  }
}
