.check-email {
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #3B4B53;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #BABFCE;

  }

  span {
    color: #FEB739;
    text-decoration-line: underline;
  }
}

.centerImage {
  text-align: center;
  display: block;
  height: 225px;
}