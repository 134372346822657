.caroucel {
    overflow: hidden;
    h2 {
        font-family: 'Montserrat';
        font-style: bold;
        font-weight: 800;
        font-size: 30px;
        line-height: 48px;
        text-align: center;
        color: #FEB739;
    }

    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 47px;
        text-align: center;
        color: #F9F9F9;

    }
}

.image__container {
    position: relative;
    display: flex;
    width: 300%;
    height: 100%;
    transition: all 0.5s ease-in;
}

.image__container .image {
    width: 100%;
    height: 100%;
}

.image__container .caption {
    width: 100%;
    margin-top: 60%;
}

.image:first-child {
    background: url("../../images/ic_onboarding_1.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.image:nth-child(2) {
    background: url("../../images/ic_onboarding_2.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.image:nth-child(3) {
    background: url("../../images/ic_onboarding_3.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.nav__dots {
    position: absolute;
    z-index: 9;
    display: flex;
    left: 50%;
    bottom: 15%;
    transform: translateX(-50%);
}

.dot {
    height: 18px;
    width: 18px;
    background-color: #feb63982;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
}

.image__container.first-active:first-child {
    margin-left: 0;
}

.image__container.second-active:first-child {
    margin-left: -100%;
}

.image__container.third-active:first-child {
    margin-left: -200%;
}

.nav__dots.first-current #dot1 {
    background-color: #FEB739;
}

.nav__dots.second-current #dot2 {
    background-color: #FEB739;
}

.nav__dots.third-current #dot3 {
    background-color: #FEB739;
}